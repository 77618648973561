import React from "react";
import I18nContext from "../lib/i18n-context";

const OurReachStats = () => {
  const { locale } = React.useContext(I18nContext);

  const data = locale.ourReachStats;

  return (
    <section className="max-w-screen-xl container px-6 md:px-12 lg:px-20 xl:px-24 py-10 md:py-16 lg:py-20 space-y-6 md:space-y-10 lg:space-y-16">
      <h2 className="text-2xl md:text-3xl lg:text-5xl font-arima lg:leading-11 max-w-5xl">
        
      </h2>
      <div className="md:flex md:space-x-10 lg:space-x-20 space-y-8 md:space-y-0 text-darkblue">
        <div className="md:w-1/2">
          <p className="text-lg md:text-xl lg:text-2xl font-poppins max-w-lg">
            {data.description}
          </p>
        </div>
        <div className="md:w-1/2">
          <figure>
            <blockquote className="font-extrabold italic font-poppins text-lg md:text-xl lg:text-2xl max-w-lg">
              <p>
                {data.quote}
              </p>
            </blockquote>
            <figcaption className="pt-4 md:pt-6 font-light font-arima text-xs md:text-sm lg:text-lg">
              {data.company}
            </figcaption>
          </figure>
        </div>
      </div>
    </section>
  );
};

export default OurReachStats;

import React from "react";
import I18nContext from "../lib/i18n-context";

const OurReachStatement = () => {
  const { locale } = React.useContext(I18nContext);

  const data = locale.ourReachStatement;
  
  return (
    <section className="bg-lightgrey space-y-12 px-6 md:px-12 lg:px-20 xl:px-24 py-10 xl:py-0">
      <div className="container max-w-screen-xl md:flex md:space-x-10 lg:space-x-16">
        <div className="md:w-1/2 flex items-center">
          <h2 className="font-poppins text-xl md:text-2xl lg:text-4xl lg:leading-11">
            {data.description}
          </h2>
        </div>
        <div className="md:w-1/2">
          <img
            src="/assets/our-reach-artboard.png"
            alt=""
            className="z-10 xl:scale-125 transform"
          />
        </div>
      </div>
    </section>
  );
};

export default OurReachStatement

import React, { useEffect, useState, useRef } from 'react'
import Layout from '../components/layout'
import NavBar from '../components/nav'
import Footer from '../components/Footer'
import Seo from '../components/Seo'
import 'aos/dist/aos.css'
import AOS from 'aos'
import OurReachBanner from '../components/OurReachBanner'
import OurReachTestimonial from '../components/OurReachTestimonial'
import OurReachStatement from '../components/OurReachStatement'
import OurReachBrands from '../components/OurReachBrands'
import OurReachStats from '../components/OurReachStats'
import OurClients from '../components/OurClients'
import OurReachWhatWeDo from '../components/OurReachWhatWeDo'
import convertVideo from '../lib/convert-video'

const OurChannels = () => {
    const [videoOverlay, setVideoOverlay] = useState(true)

    const playVideo = () => {
        setVideoOverlay(false)
    }

    useEffect(() => {
        AOS.init()
        AOS.refresh()
    }, [])

    return (
        <Layout>
            <Seo title={'Our reach'} description={'Sales & marketing channels we operate on'} />
            <NavBar
                navClass={'white-navbar'}
                logo={'/assets/main-logo-blue.svg'}
                class={'text-darkblue border-darkblue'}
            />
            <div className="text-darkblue pt-16 pb-12">
                <OurReachBanner />
                <OurReachTestimonial />
                <OurReachStatement />
                <OurReachBrands />
                <OurReachStats />
                <section className="max-w-screen-xl container px-6 md:px-12 lg:px-20 xl:px-24 py-10 md:py-16 lg:py-20">
                    <div style={{ paddingBottom: '56.25%' }} className="relative h-0">
                        <iframe
                            className="absolute top-0 left-0 h-full w-full"
                            src={convertVideo('https://vimeo.com/763433152', videoOverlay ? false : true)}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            frameBorder="0"
                        ></iframe>
                        {videoOverlay && (
                            <div
                                onClick={() => playVideo()}
                                className="absolute inset-0 cursor-pointer"
                            >
                                <img
                                    src="/assets/grenade-bar.png"
                                    alt=""
                                    className="w-full h-full object-cover"
                                />
                                <img
                                    src="/assets/play-button.svg"
                                    alt="Play video"
                                    className="absolute transform top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-14 w-14 md:h-20 md:w-20"
                                />
                            </div>
                        )}
                    </div>
                </section>
                <OurClients />
            </div>
            <OurReachWhatWeDo />
            <Footer />
        </Layout>
    )
}

export default OurChannels

import React from "react";
import I18nContext from "../lib/i18n-context";

const OurReachBrands = () => {
  const { locale } = React.useContext(I18nContext);

  const data = locale.ourReachBrands;

  return (
    <section className="bg-darkblue">
      <div className="container max-w-screen-xl px-10 py-18 xl:py-24 lg:px-16 text-white text-center">
        <h2 className="font-arima text-2xl md:text-3xl lg:text-4xl mb-6 md:mb-10 lg:mb-16">
          {data.title}
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 place-content-center place-items-center lg:place-items-stretch lg:grid-cols-3 xl:grid-cols-5 gap-12">
          <div>
            <img className="p-5" src="/assets/brand-one.png" alt="Globe icon" />
            <div className="space-y-3">
              <h4 className="text-3xl font-light font-arima">
                {data.keyFacts[0].number}
              </h4>
              <h5 className="text-3xl font-light font-arima">
                {data.keyFacts[0].title}
              </h5>
              <p className="font-light font-poppins text-sm">
                {data.keyFacts[0].description}
              </p>
            </div>
          </div>
          <div>
            <img
              className="p-5"
              src="/assets/brand-two.png"
              alt="Distrubute icon"
            />
            <div className="space-y-3">
              <h4 className="text-3xl font-light font-arima">
                {data.keyFacts[1].number}
              </h4>
              <h5 className="text-3xl font-light font-arima">
                {data.keyFacts[1].title}
              </h5>
            </div>
          </div>
          <div>
            <img
              className="p-5"
              src="/assets/brand-three.png"
              alt="link icon"
            />
            <div className="space-y-3">
              <h4 className="text-3xl font-light font-arima">
                {data.keyFacts[2].number}
              </h4>
              <h5 className="text-3xl font-light font-arima">
                {data.keyFacts[2].title}
              </h5>
            </div>
          </div>
          <div>
            <img
              className="p-5"
              src="/assets/brand-four.png"
              alt="house icon"
            />
            <div className="space-y-3">
              <h4 className="text-3xl font-light font-arima">
                {data.keyFacts[3].number}
              </h4>
              <h5 className="text-3xl font-light font-arima">
                {data.keyFacts[3].title}
              </h5>
            </div>
          </div>
          <div>
            <img
              className="p-6"
              src="/assets/brand-five.png"
              alt="speech icon"
            />
            <div className="space-y-3 sm:pt-4">
              <h4 className="text-3xl font-light font-arima">
                {data.keyFacts[4].number}
              </h4>
              <h5 className="text-3xl font-light font-arima">
                {data.keyFacts[4].title}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurReachBrands;

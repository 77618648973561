import React from "react";
import I18nContext from "../lib/i18n-context";

const OurReachBanner = () => {
  const { locale } = React.useContext(I18nContext);

  const data = locale.ourReachBanner;

  return (
    <section
      className="pt-32 pb-8 text-darkblue bg-fixed bg-cover bg-center px-6 md:px-12 lg:px-20 xl:px-24"
      style={{ backgroundImage: `url(${"/assets/ideas-bg-cream.svg"})` }}
    >
      <div className="space-y-5 pb-16 max-w-screen-xl container text-center">
        <h1 className="text-xl lg:text-2xl xl:text-3xl font-arima font-light">
          {data.title}
        </h1>
        <h2 className="text-3xl lg:text-4xl xl:text-5xl font-poppins font-light">
          {data.description}
        </h2>
      </div>
    </section>
  );
};

export default OurReachBanner;

import React from "react";
import I18nContext from "../lib/i18n-context";

const OurReachTestimonial = () => {
  const { locale } = React.useContext(I18nContext);

  const data = locale.ourReachTestimonial;

  return (
    <section className="max-w-screen-xl container px-6 md:px-12 lg:px-20 xl:px-24 pb-10 md:pb-16 lg:pb-20">
      <figure>
        <blockquote className="text-3xl md:text-4xl lg:text-5xl lg:leading-12 font-poppins">
          {data.quote}
        </blockquote>
        <figcaption className="pt-6 md:pt-10 font-arima text-xl md:text-2xl font-light">
          {data.role}
          <br />
          <cite className="font-medium">{data.company}</cite>
        </figcaption>
      </figure>
    </section>
  );
};

export default OurReachTestimonial
